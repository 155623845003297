 <!--start top header-->
 <header class="top-header">
	<nav class="navbar navbar-expand">
	  <div (click)="toggleSidebar()" class="mobile-toggle-icon d-xl-none">
		  <i class="bi bi-list"></i>
		</div>
		<div class="top-navbar d-none d-xl-block">
		<ul class="navbar-nav align-items-center">

		</ul>
		</div>
		<div class="search-toggle-icon d-xl-none ms-auto">
		  <!-- <i class="bi bi-search"></i> -->
		</div>
		<form class="searchbar d-none d-xl-flex ms-auto">
			<!-- <div class="position-absolute top-50 translate-middle-y search-icon ms-3"><i class="bi bi-search"></i></div>
			<input class="form-control" type="text" placeholder="Type here to search">
			<div class="position-absolute top-50 translate-middle-y d-block d-xl-none search-close-icon"><i class="bi bi-x-lg"></i></div> -->
		</form>
		<div class="top-navbar-right ms-3">
		  <ul class="navbar-nav align-items-center">
		  <li class="nav-item dropdown dropdown-large">
			<a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">
			  <div class="user-setting d-flex align-items-center gap-1">
          <i class="bi bi-person-circle header-user-icon"></i>
				<!-- <div class="user-name d-none d-sm-block">Admin</div> -->
			  </div>
			</a>
			<ul class="dropdown-menu dropdown-menu-end" >
				<li>
          <span class="dropdown-item">
            <div class="d-flex align-items-center">
              <div class="setting-icon"><i class="bi bi-person-circle bluelogicolor"></i></div>
              <div class="setting-text ms-2"><span>{{UserName}}</span></div>
            </div>
          </span>
           <hr class="m-1">
				  <a class="dropdown-item">
					 <div class="d-flex align-items-center cursor-pointer" (click)="logout()">
					   <div class="setting-icon"><i class="bi bi-lock-fill"></i></div>
					   <div class="setting-text ms-2"><span>Logout</span></div>
					 </div>
				  </a>
				</li>
			</ul>
		  </li>
		  </ul>
		  </div>
	</nav>
  </header>
	 <!--end top header-->
