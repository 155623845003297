<svg height="50px"  width="50px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
 style="enable-background:new 0 0 24 24;" xml:space="preserve">

 <!-- <path stroke="black" stroke-width="1" fill="currentColor" fill-opacity="0.3" d="M20,10V8.3C20,7.6,19.4,7,18.7,7H3.3C2.6,7,2,7.6,2,8.3v7.3C2,16.4,2.6,17,3.3,17h15.3c0.7,0,1.3-0.6,1.3-1.3V14h2v-4H20z"/> -->
<path stroke="black" stroke-width="1.7" fill="currentColor" fill-opacity="0.3" d="M20,12v3.3C21,7.6,19.4,7,18.7,7H3.3C2.6,7,2,7.6,2,8.3v7.3C2,16.4,2.6,17,3.3,17h15.3c0.7,0,2-3,1.3-1.3V14h2v-4H20z"/>
    <clipPath id="pxb-battery-clip" >
      <path  overflow="visible" d="M20,8.33C20,7.6,19.4,7,18.67,7H3.34C2.6,7,2,7.6,2,8.33v7.33C2,16.4,2.6,17,3.33,17h15.34C19.4,17,20,16.4,20,15.67V8.33z"></path>

    </clipPath>

		<!-- This is the rectangle you need to fill: 0-18 -->

		<rect fill="currentColor" id="fill_me_up" x="2" y="7" clip-path="url(#pxb-battery-clip)" [attr.width]="value*.18" height="10" ></rect>
    <text *ngIf="value!=0" x="6" y="14" font-family="" font-size="5" fill="white" style="font-weight: 600;">{{value}}</text>
    <text *ngIf="value==0" x="9" y="14" font-family="" font-size="5" fill="white" style="font-weight: 600;">0</text>
</svg>
<!-- <h5 style="color: black;
position: inherit;
left: -37px;
top: 1px;
font-size: 10px;
font-weight: 600; margin-left: -39px;">{{value}}</h5> -->
